function initSocialSlider() {
    var sliders = document.querySelectorAll('.strate-footer-social-wall .wrapper-small .th-slider');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSliderSocial(slider);
    }

    function initSliderSocial(slider) {
        // console.log("sliders", sliders);
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }
        var navButtons = parent.querySelectorAll('.nav-buttons button');
        var dotsWrapper = parent.querySelectorAll('.nav-dots');


        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            scrollModeMaxWidth: 1024,
            oninit: function (slider) {
                // console.log('scrollModeMaxWidth', this.scrollModeMaxWidth);
                console.log(slider);
                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper[0]);
                }

            },
            onchange: function (slider) {

                thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);

                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper[0]);
                }
            }
        });

    }
}


function isSliderSocialReady() {
    if (typeof thSlider === "undefined") {
        setTimeout(isSliderSocialReady, 1000);
        return false;
    }
    initSocialSlider();
}

isSliderSocialReady();

