$('[name="nav-primary-choices"]').on('change', function(e){
    var id = $(this).attr('id');

    if($(this).prop('checked')){
        $('[name="nav-primary-choices"]').prop( "checked", false );
        $('[for^="nav-primary-rb-"]').removeClass('actif');

        $(this).prop('checked', true);
        $('[for="'+id+'"]').addClass('actif');
    }else{
        $('[name="nav-primary-choices"]').prop( "checked", false );
        $('[for^="nav-primary-rb-"]').removeClass('actif');
    }
});