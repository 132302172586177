$('form input[type="text"], form textarea, form input[type="number"], form input[type="email"], form input[type="url"], form input[type="tel"], form input[type="password"], form .selectric-wrapper').each(function(){

    var _this = $(this);

    _this.prev('label').addClass('input-label');

    // A l'initialisation, si on a déjà une valeur (pas pour le selectric)
    if(_this.val() || _this.attr('placeholder')) {
        _this.parent().addClass('focused');
    }
    if(_this.hasClass('selectric-wrapper')) {
        _this.parent().addClass('focused');
    }

    //Focus in et out pour déplacer le label
    _this.on('focus',function(){
        _this.parent().addClass('focused');
    });
    _this.on('focusout',function(){
        setTimeout(function(){
            console.log(_this);
            if(!_this.val() && !_this.hasClass('selectric-wrapper') && !_this.attr('placeholder')) {
                _this.parent().removeClass('focused');
            }
        }, 200);
    });

    //Au hover on colorise le label
    _this.on('hover',function(){
        _this.prev('label').addClass('hover');
    });

    //si il y a un erreur, n rajoute une classe pour changer le style
    if(_this.parent().find('.frm_error').length > 0){
        _this.parent().addClass('error');
    }


});