// Dans le "bloc" sit à propos, on ajoute un bouton pour voir plus ou moins si le texte à dépassé une hauteur de 400px
var sitProposHeight = $('.single-sit .text');
var btnShowMorePropos = $('.single-sit .btn-view-more');

sitProposHeight.each(function () {
    if ($(this).height() >= 120) {
        $(this).parent().addClass('view-more-active');

        btnShowMorePropos.on('click', function () {
            $(this).prev().toggleClass('toggle-height');
        });
    }
});


(function () {
    var sliders = document.querySelectorAll('.js-sit-gallery.th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }

    function initSlider(slider) {

        var parent = slider.parentNode;
        var navButtons = parent.querySelectorAll('.nav-buttons button');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: 1024,
            oninit: function (slider) {
                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }
            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
            }
        });

        return s;
    }

})();

