// Selectric est activé par défaut sur toutes les selects,
// mais nous on le veut pas avoir sur le sélecteur de langues en version tablette / mobile
$("#lang-dropdown-mobile").selectric('destroy');

// Ce script est nécessaire pour forcer le changement de page au click sur une option
$('#lang-dropdown-mobile').change(function () {
    var val = $(this).val();
    window.location.href = val;
});

$('label.barre-navigation__menu__link').on('click', function(){
    th_overlay.open("overlay-menu");
});